import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../layout';
import SEO from '../../components/Seo/Seo';
import TopicsBar from '../../components/TopicsBar/TopicsBar';
import TopicsPage, { FeaturedTopics, FeaturedTopic } from './topics.style';

const Topics = ({
  data: {
    allWordpressAcfOptions: { nodes },
  },
}) => {
  const { featured_topics: featuredTopics } = nodes[0].options;

  return (
    <Layout>
      <SEO title="topics" />
      <TopicsPage className="topics">
        <TopicsBar />
        <FeaturedTopics className="feature_topics">
          {featuredTopics.map(({ name, slug, count }) => (
            <Link to={`/tag/${slug}`} key={slug}>
              <FeaturedTopic>
                <dl>
                  <dt>{count}</dt>
                  <dd>{name}</dd>
                </dl>
              </FeaturedTopic>
            </Link>
          ))}
        </FeaturedTopics>
      </TopicsPage>
    </Layout>
  );
};

export default Topics;

export const topicsQuery = graphql`
  {
    allWordpressAcfOptions {
      nodes {
        options {
          featured_topics {
            name
            slug
            count
          }
        }
      }
    }
  }
`;
